<template>
  <v-form>
    <h1>{{ title }}</h1>
    <v-row>
      <v-col cols="12" xl="6" lg="6" md="6" sm="12">
        <v-text-field label="Key" v-model="setting.key"></v-text-field>
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="12">
        <v-text-field label="Value" v-model="setting.value"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn outlined class="button" @click="onCancel()">
        <v-icon left>mdi-cancel</v-icon>Cancel
      </v-btn>
      <v-btn outlined class="button" @click="onSave()">
        <v-icon left>mdi-content-save</v-icon>Save
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import apiService from "@/_services/api-service"
import store from "@/store"
import router from "@/router"
import registry from '@/_services/registry-service'

export default {
  components: {
  },

  created() {
    this.get()
  },
  computed: {},

  data: () => ({
    title: "Edit Setting",
    setting: {
      id: 0,
      key: '',
      value: '',
    },
  }),

  methods: {
    get() {
      this.title = this.$route.params.id == 0 ? "Add Setting" : "Edit Setting"
      const url = "registry/" + this.$route.params.id
      apiService.get(url, this.getSuccess, this.getError)
    },

    getError(error) {
      console.log(error)
    },

    getSuccess(result) {
      this.setting = result.data;
    },

    onCancel() {
      router.go(-1)
    },

    onSave() {
      if(this.setting.id == 0) {
        apiService.post('registry', this.setting, this.saveSuccess, this.saveError)
      } else {
        const url = 'registry/' + this.setting.id
        apiService.put(url, this.setting, this.saveSuccess, this.saveError)
      }
    },

    saveError(error) {
      const notification = {
        title: "Save Image",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    saveSuccess(result) {
      console.log(result)
      registry.load()
      router.go(-1)
    }
  },
  router,
  store,
};
</script>

<style lang="scss" scoped>
.button {
  margin-right: 20px;
}
</style>